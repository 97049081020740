import React, { useContext } from 'react';
import * as classes from './contact.module.scss';
import { DaylightContext } from '../contexts/daylightContext';
import RiverSection from '../components/riverSection/RiverSection';
import SEO from '../components/SEO';

import Board from '../components/rainSweeperWidget/Board';
function ContactPate(props) {
	const { daylight } = useContext(DaylightContext);

	return (
		<>
			<div
				className={` ${
					daylight ? classes.contactWrap : classes.contactWrapDark
				}`}
			>
				<SEO title="Contact" />
				<main id="main">
					<h1>Contact</h1>
					<p>
						Email me at{' '}
						<a className="inlineLink" href="mailto:contact@marie.ie">
							contact@marie.ie
						</a>{' '}
						and/or have a quick game of RainSweeper.
					</p>

					<Board />
				</main>
			</div>
			<div style={{ overflow: 'hidden' }}>
				<RiverSection sail={true} />
			</div>
		</>
	);
}

export default ContactPate;
