import React, { useState, useEffect, useRef } from 'react';

export default function GameInfo({
  gameOver,
  newGame,
  score,
  setNewGame,
  win,
  roll,
  numLives ='',
  error,
  loading,
}) {
  const lives = useRef(numLives);
  const [emoji, setEmoji] = useState({ emoji: '🤔', desc: 'thinking emoji' });


  useEffect(() => {
    if(!numLives) return;
    if (numLives < lives.current) {
      setEmoji({ emoji: '🙄', desc: 'eyeroll emoji' });

      const timer = setTimeout(
        () => setEmoji({ emoji: '🤔', desc: 'thinking emoji' }),
        300
      );

      // Clean up setTimeout.
      return function cleanup() {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [numLives, lives]);

  function decideEmoji() {
    if (gameOver && typeof newGame === 'undefined') {
      return {
        emoji: '😴',
        desc: 'sleeping emoji',
      };
    } else if (!gameOver && typeof newGame === 'boolean') {
      return emoji;
    } else if (win && gameOver && typeof newGame === 'boolean') {
      return { emoji: '😀', desc: 'grinning emoji' };
    } else if (
      typeof newGame === 'boolean' &&
      typeof win === 'boolean' &&
      gameOver
    ) {
      return { emoji: '😒', desc: 'sad emoji' };
    } else {
      return '';
    }
  }

  return (
    <div style={styles.gameInfo}>
      <h2 style={styles.gameTitle}>
          <span style={styles.gameTitleMain}>Galway Rain Sweeper</span> { ' '}<br/>
          <span style={styles.gameTitleSpan}>Data from Athenry weather station.</span>
      </h2>
      <div style={styles.gameTemp}>
        <span style={styles.score}>Score: {score}</span>
        <span
          style={{ fontSize: '1.563em' }}
          role="img"
          aria-label={decideEmoji().desc}
        >
          {' '}
          {decideEmoji().emoji}
        </span>

        {!gameOver ? (
          <>
            <span style={styles.umbrellas}>
              {numLives && numLives < 4 && numLives > 0
                ? Array.from(Array(numLives)).map((_, i) => '🌂')
                : numLives === 0
                ? '⚠️'
                : ``}
                {/* : `🌂 x ${numLives}`} */}
            </span>
            <span style={styles.round}>Round: {roll + 1}</span>
          </>
        ) : (
          <>
            <span style={styles.umbrellas}></span>
            <button
            
              id="0"
              className='button'
              style={{ display: 'grid', justifySelf: 'flex-end' }}
              disabled={Boolean(error) || loading}
              onClick={() => setNewGame(true)}
            >
              {win ? 'Next Round' : 'New Game'}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

const styles = {
  gameInfo: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    borderRadius: '0.2em',
    border: '2px inset #ffffff',
    alignContent: 'flex-start',
    padding: '0.5em 0em',
    rowGap: '1em',
  },
  gameTitle: {
    color: '#353131',
    fontSize: '1em',
    letterSpacing: '-0.3px',
    // fontWeight: '500',
    marginLeft: '1em'
    
    
  },
  gameTitleMain: {
    textTransform: 'uppercase',

  },
  gameTitleSpan: {
    fontWeight: 'normal'
  },
  gameTemp: {
    justifyItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    // minHeight: '4em',
    margin: '0em 1em',
    backgroundColor: '#cac4b9',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    // justifyContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
  },
  score: {
    fontFamily: 'monospace',
    fontWeight: '700',
    padding: '0.2em',
    fontSize: '1em',
    color: 'var(--red)',
    backgroundColor: '#ffffff',
    borderRadius: '0.2em',
    display: 'grid',
    justifySelf: 'flex-start',
  },
  round: {
    fontFamily: 'monospace',
    fontWeight: '700',
    fontSize: '1em',
    padding: '0.2em',
    color: '#ffffff',
    backgroundColor: '#ff401a',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: '0.2em',
    display: 'grid',
    justifySelf: 'flex-end',
  },
};
