export const DIRECTIONS = [
	'west',
	'northWest',
	'north',
	'northEast',
	'east',
	'southEast',
	'south',
	'southWest',
];

export const NUM_DAYS_IN_ROW = 8;
export const NUM_DAYS_IN_GAME = 64;
