import React from 'react';

export default function Nasties({ numNastyNeighbours, classes }) {
	const color = {
		color:
			numNastyNeighbours === 0
				? '#ffffff'
				: numNastyNeighbours === 1
				? '#0c8f2c'
				: numNastyNeighbours === 2
				? '#396fb4'
				: '#ff401a',
	};
	return (
		<span className={classes.nasties} style={{ ...color }}>
			{numNastyNeighbours}
		</span>
	);
}
